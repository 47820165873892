import * as chat from './chat';
import * as app from './app';
export * as actionTypes from './actionTypes';

const actions = {
  chat,
  app,
};

export default actions;
